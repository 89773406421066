@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400&display=swap);
:root {
  --color-bg: #eef0f1;
  --color-text: #08090a;
  --color-blue: #3b49df;
  --color-red: #df3b3b;
  --color-green: #3bdf72;
  --color-gray: #b5bdc4;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  background-color: #eef0f1;
  background-color: var(--color-bg);
  color: #08090a;
  color: var(--color-text);
}

main {
  padding: 1rem 10vw;
}

h1 { font-size: 2rem; }
h2 { font-size: 1.5rem; }
h2 { font-size: 1.25rem; }

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

img {max-width: 100%;}

input {
  display: inline-block;
  outline: none;
  border: none;
  font-size: 1.5rem;
  width: 100%;
  padding: 5px 10px;
}

fieldset {
  border: none;
  padding: 1rem 0;
  font-size: 1.25rem;
}

code {
  overflow-x: scroll;
}

/* Navbar */

.navbar {
  height: 70px;
  width: 100%;
  background: white;
  color: var(--colors-text);
  position: fixed;
  top: 0;
  padding: 0 10vw;
  font-weight: bold;
  border-bottom: 1px solid #b5bdc4;
  border-bottom: 1px solid var(--color-gray);
  z-index: 99;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100%;
}

.navbar img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.navbar li {
  border-radius: 50%;

}

/* Buttons */

.btn, button {
  background-color: #b5bdc4;
  background-color: var(--color-gray);
  border: none;
  color: #08090a;
  color: var(--color-text);
  padding: 1rem 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-decoration: none;
  font-family: 'Noto Sans', sans-serif;
  font-weight: bold;
  border-radius: 0.25rem;
  cursor: pointer;
  margin: 0.5rem 1rem 0.5rem 0;
}

@media only screen and (max-width: 768px) {
  button {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }
}


button:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

button:disabled,
button[disabled]{
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
  cursor: not-allowed;
}

button.btn-blue {
  background-color: #3b49df;
  background-color: var(--color-blue);
  color: white;
}





button.btn-red {
  background-color: #df3b3b;
  background-color: var(--color-red);
  color: white;
}

button.btn-green {
  background-color: #3bdf72;
  background-color: var(--color-green);
  color: white;
}

button.btn-google {
  background-color: white;
  color: #08090a;
  color: var(--color-text);
}

.btn-google img {
  width: 30px;
  margin-right: 10px;
}

button.btn-logo {
  background-color: #08090a;
  background-color: var(--color-text);
  color: white;
  text-transform: uppercase;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
}

/* Cards */

.card {
  padding: 2rem;
  margin: 1rem 0;
  background-color: white;
  border: 1px solid #b5bdc4;
  border: 1px solid var(--color-gray);
  border-radius: 8px;
}

.card footer {
  display: -webkit-flex;
  display: flex;
}

.card-img-center {
  width:20%;
  display:block;
  margin:auto;
  border-radius: 50%;
  max-width: 150px;
}

.card-info {
  color: white;
  background: #3b49df;
  background: var(--color-blue);
}

/* Loader */

.loader {
  border: 10px solid #eef0f1;
  border: 10px solid var(--color-bg);
  border-top: 10px solid #3b49df;
  border-top: 10px solid var(--color-blue);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

/* File uploads */

input[type="file"] {
  display: none;
}

.upload-snippet {
  width: 75%;
    margin-left: auto;
    background: white;
    padding: 5px;
    margin: 5px 0;
}
/* Utilities */

.push-left {
  margin-left: auto;
}

.text-sm {
  font-size: 0.85rem;
}

.text-danger {
  font-weight: bold;
  color: #df3b3b;
  color: var(--color-red);
}

.text-success {
  font-weight: bold;
  color: #3bdf72;
  color: var(--color-green);
}

.text-info {
  font-weight: bold;
  color: #3b49df;
  color: var(--color-blue);
}

.box {
  /* display: flex; */
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.box-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-content: center;
          align-content: center;
  text-align: center;
}

.hidden { display:  none; }





