@font-face {
    font-family: 'GucciSans-Bold';
    src: url('./resources/fonts/GucciSans-Bold.ttf');
    /* IE9 Compat Modes */
}

@font-face {
    font-family: 'GucciSans-Book';
    src: url('./resources/fonts/GucciSans-Book.woff2');
    /* IE9 Compat Modes */
}

.gucci_text {
    font-family: 'GucciSans-Book' !important;
}

.gucci_text_bold {
    font-family: 'GucciSans-Bold' !important;
}

.absolute {
    position: absolute;
}

.flex {
    display: flex;
}

.grid {
    display: grid;
}

.ml-3p {
    margin-left: 3px !important;
}

.ml--35 {
    margin-left: -35px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.mr-10 {
    margin-right: 10px !important
}

.mb-10 {
    margin-bottom: 10px !important
}

.mt-10 {
    margin-top: 10px !important
}

.mt-20 {
    margin-top: 20px !important
}

.mt--5 {
    margin-top: -5px !important
}

.mt--50 {
    margin-top: -50px !important
}

.fs-15 {
    font-size: 15px;
}

.pointer {
    cursor: pointer
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product_list::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 7px;
}

.product_list::-webkit-scrollbar-thumb {
    background: #929292d0;
    border-radius: 10px !important;
}


/* Handle on hover */
.product_list::-webkit-scrollbar-thumb:hover {
    background: #020202;
}

.products_container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 7px;
}

.products_container::-webkit-scrollbar-thumb {
    background: #929292d0;
    border-radius: 10px !important;
}


/* Handle on hover */
.products_container::-webkit-scrollbar-thumb:hover {
    background: #020202;
}

.MuiDialogContent-root::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 7px;
}

.MuiDialogContent-root::-webkit-scrollbar-thumb {
    background: #929292d0;
    border-radius: 10px !important;
}


/* Handle on hover */
.MuiDialogContent-root::-webkit-scrollbar-thumb:hover {
    background: #020202;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}

.center_align {
    align-items: center
}

.w-99 {
    width: 99%;
}

.w-50 {
    width: 50%;
}

.w-20 {
    width: 20% !important;
    ;
}

.w-15 {
    width: 15% !important;
    ;
}

.h-27 {
    height: 27px;
}

.w-100 {
    width: 100%;
}

.ml-8 {
    margin-left: 8px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.ml-20 {
    padding-left: 20px !important;
}

.capitalize {
    text-transform: capitalize;
}

/*LOGIN PAGE */
.login_container {
    width: 100%;
    height: 100vh;
    background-image: url('./resources/img/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.login_icon {
    position: absolute;
    left: 15px;
    top: 22%;
}

.login_logo {
    /* background-image: url('./resources/img/vector.png');*/
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100vh;
    background-position-y: center;
    background-position-x: center;
}

.logo_values {
    position: absolute;
    background-position-y: center;
    background-position-x: center;
    top: 20%;
    left: 20%;
    width: 60%;
    height: 60%;
}

.subtext_login {
    color: white;
    font-size: 2vw;
    position: absolute;
    top: 20%;
    left: 44%;
}

.text_login {
    color: white;
    font-size: 4vw;
    position: absolute;
    top: 40%;
    left: 32%;
    text-shadow: 2px 2px #6f6f6f;
}

.login_button {
    width: 16%;
    height: 7%;
    background-color: #ffffff !important;
    font-family: 'GucciSans-Bold' !important;
    position: absolute !important;
    font-size: 1rem !important;
    top: 72%;
    left: 42%;
    box-shadow: 2px 2px #6f6f6f6b;
}

.login_button>span.MuiButton-label {
    margin-left: 12px;
}

.MuiCircularProgress-colorPrimary {
    color: #ca0000 !important;
}

/*HOMEPAGE*/

/*NAVBAR*/
.app_navbar {
    position: fixed;
    top: 0;
    left: 0;
    background-image: url('./resources/img/bg.jpg');
}

.right-header {
    display: inline-flex;
    position: absolute;
    right: 0;
    top: 11px;
    margin-right: 20px;
    height: 40px;
    align-items: center;

    /* check */
    background-color: black;
}

.event_demo {
    margin-top: 7px;
    left: -15px !important;
}

/*event border*/
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ca0000 !important;
}

/*event text */
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    font-family: 'GucciSans-Book' !important;
}

/*export text*/
div.right-header>button.MuiButtonBase-root.MuiButton-root.MuiButton-text.gucci-text.MuiButton-disableElevation>span.MuiButton-label {
    font-family: 'GucciSans-Book';
    margin-top: 5px;
}


/*Home Menu*/

/*Radio*/
.wrapper {
    display: inline-flex;
    height: 40px;
    width: 450px;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;

    margin-top: 2%;
}

.wrapper .option {
    background: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-right: 20px;
    margin-left: 1px;
    margin-top: 6px;
    border-radius: 5px;
    cursor: pointer;
    padding: 0px 6px;
    border: 2px solid lightgrey;
    transition: all 0.3s ease;
}

.wrapper .option .dot {
    height: 20px;
    width: 20px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;

}

.wrapper .option .dot::before {
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: #000000;
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
}

.MuiTypography-body1 {
    font-family: 'GucciSans-Book' !important;
}

input[type="radio"] {
    display: none;
}

#option-1:checked:checked~.option-1,
#option-2:checked:checked~.option-2,
#option-3:checked:checked~.option-3 {
    border-color: #000000;
    background: #000000;
}

#option-1:checked:checked~.option-1 .dot,
#option-2:checked:checked~.option-2 .dot,
#option-3:checked:checked~.option-3 .dot {
    background: #fff;
}

#option-1:checked:checked~.option-1 .dot::before,
#option-2:checked:checked~.option-2 .dot::before,
#option-3:checked:checked~.option-3 .dot::before {
    opacity: 1;
    transform: scale(1);
}

.wrapper .option span {
    font-size: 15px;
    color: #000000;
}

#option-1:checked:checked~.option-1 span,
#option-2:checked:checked~.option-2 span,
#option-3:checked:checked~.option-3 span {
    color: rgb(255, 255, 255);
}

/* filter select */

.select_filter {
    font-family: 'GucciSans-Bold' !important;
    margin-right: 10px !important;
    padding-top: 18px !important;
}

.MuiSelect-icon {
    margin-top: 7px !important;
}

/*PAGINATION*/
.MuiPaginationItem-root {
    font-family: 'GucciSans-Book' !important;
}

.Mui-selected {
    font-family: 'GucciSans-Bold' !important;
}

/*Select*/
#demo-simple-select-outlined {
    height: 26px;
    margin-top: 13px;
    padding: 2px 24px 5px 1px;
}

.demo-simple-select-outlined {
    top: 138px !important;
    font-family: 'GucciSans-Book';
}

.value-sel {
    margin-top: 12px !important;
}

div.MuiInputBase-root.MuiInput-root.MuiInput-underline.gucci_text.MuiInputBase-formControl.MuiInput-formControl>svg {
    margin-top: 5px;
}

/*LEFT VIEW*/
.summary {
    justify-content: center;
    width: 92%;
}

.center {
    justify-content: center;
}

.summary_button {
    margin-top: -14px;
    text-align: -webkit-center;
}

.complete_button {
    width: 45%;
    background-color: #f2f2f2 !important;
    font-family: 'GucciSans-Bold' !important;
    font-size: 1rem !important;
    box-shadow: 2px 2px #6f6f6f6b;
    color: #ca0000 !important;
    margin-top: 5px !important;
    margin-right: 5% !important;
}

.icon {
    margin-right: 0 !important;
}

/*PRODUCT*/
.MuiButton-startIcon {
    margin-right: 0px !important;
}

.add_hotspot_button {

    /* color: #ca0000 !important;*/

    width: 3%;
    background-color: #f2f2f2 !important;
    box-shadow: 2px 2px #6f6f6f6b;
    margin-top: -4px !important;
    margin-right: 5% !important;
}

.add_wishlist_button {

    color: #ca0000 !important;


    margin-top: -4px !important;
    margin-right: 5% !important;
}

.delete_button {
    height: 30px !important;
    width: 30px !important;
    margin-top: -4px !important;
    margin-right: 5% !important;
}

.hotspot_button {
    /*margin-top: -3px;*/
    text-align: -webkit-center;
    height: 32px;
    margin-left: 10px;
}

.custom_hotspot_button {
    text-align: -webkit-center;
    text-transform: uppercase;
    height: 32px;
    margin: 5px;
    padding: 12px;
    background-color: #e0e0e0;
    font-family: "GucciSans-Book" !important;
    cursor: pointer !important;
}


.custom_hotspot_div {
    text-align: -webkit-center;
    text-transform: uppercase;
    height: 32px;
    margin-top: 5px;
    margin-right: 2px;
    padding: 6px;
    background-color: #e0e0e0;
    cursor: pointer !important;
    border-radius: 5px 5px 0 0;
    font-size: 15px;
}

.selected_hotspot {
    background-color: rgba(202, 202, 202, 255);
}

button {
    font-family: inherit
}

.red {
    color: #ca0000 !important
}

.MuiInput-underline::after {
    border-bottom: 2px solid #ca0000 !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #ca0000 !important;
}

.template_button {
    background-color: #f2f2f2 !important;
    box-shadow: 2px 2px #6f6f6f6b;
    margin-top: -4px !important;
    font-family: "GucciSans-Book" !important;
}

.error_message {
    font-family: 'GucciSans-Bold' !important;
    text-align: center;
    margin-top: 200px;
}

/*WISHLIST*/
.wrap {
    flex-wrap: wrap;
}

.template_button_wishlist>span.MuiButton-label {
    place-self: flex-start;
}


.ellipsis-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.template_button_wishlist {
    background-color: #f2f2f2 !important;
    box-shadow: 2px 2px #6f6f6f6b;
    margin-top: -4px !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
    padding: 30px;
    width: 430px;
    height: 150px;
    text-align: left !important;
}

.template_button_wishlist>span.MuiButton-label {
    justify-content: left;
}

.wishlist_icon {
    width: 37px !important;
    height: 29px !important;
    margin-top: -3px;
}

.wishlist_name {
    position: absolute;
    left: 15px;
    top: 11px;
}

.wishlist_details {
    margin-top: 20px;
}

.wishlist_product_details {
    margin-top: 20px;
    margin-left: -5px;
}

.left_align {
    margin-left: auto;
    margin-right: 6px;
}

#client_new_wishlist_dialog {
    margin-top: 10px;
}

#notes_new_wishlist_dialog {
    margin-top: 30px;
}

.wishlist_title {
    align-items: center;
    margin-top: 10px;
}

.wishlist_vert_icon {
    margin-top: -20px;
    position: absolute;
    left: 346px;
    top: 20px;

}

.template_product_wishlist {
    background-color: #f2f2f2 !important;
    box-shadow: 2px 2px #6f6f6f6b;
    margin-top: 8px !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
    padding: 20px;
    width: 430px;
    height: 150px;
    text-align: left !important;
}

.self_center {
    align-self: center;

}

.remove_icon {
    margin-top: -20px;
    position: absolute;
    left: 346px;
    top: -20px;
}

.remove_product_wishlist {
    position: absolute
}

.wishlist_option_button {
    width: 45%;
    background-color: #f2f2f2 !important;
    font-family: 'GucciSans-Bold' !important;
    font-size: 1rem !important;
    box-shadow: 2px 2px #6f6f6f6b;
    color: #ca0000 !important;
    margin-top: 2px !important;
    margin-right: 5% !important;
}

/* User */
.user_card_icon {
    width: 50px !important;
    height: 40px !important;
}

.lowercase {
    text-transform: lowercase !important;
}

.top-card {
    display: flex;
}

.user-position {
    position: absolute;
    top: 153px;
    border-top: 1px solid #dee2e6;
    bottom: 0;
    width: 380px;
    font-size: 16px;
    text-align: center;
    right: 0;
    left: auto;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 45px;
    font-family: 'GucciSans-Bold';
    padding-top: 4px;
}


.toggle-button {
    height: 21px;
    width: 22px;
    position: absolute;
    cursor: pointer;
    -webkit-appearance: none;
    /*hides the default checkbox*/
    position: absolute;
    transition: 0.10s;
    background-color: #ffffff;
    text-align: center;
    color: white;
    border-radius: 3px;
    outline: none;
    border: 1px solid black;
    padding: 0px !important;
    font-size: 1em;
    top: 3px;
}

.toggle-button:checked {
    background-color: #f2f2f2;
    padding: 0px !important;
}

.toggle-button:checked:before {
    content: '✔';
    color: #ca0000 !important;
    padding: 0px !important;
    font-size: 1em;
}

.check_container {
    height: 25px;
    align-items: center;
    display: flex;
    min-width: 300px;
    padding-right: 25px;
}

/*
.user-position span {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}
*/
.actions_add_user {
    margin-left: 197px;
    margin-top: -35px;
    display: flex;
}

.actions_edit_user {
    margin-left: -25px;
    margin-top: -35px;
    display: flex;
}

.add_user_ico {
    position: absolute;
    top: 44%;
    left: 45%;
    cursor: pointer;
}

.avatar-md {
    height: 3.5rem;
    width: 3.5rem;
}

.card_component {
    width: 380px;
    height: 210px;
    margin-right: 25px;
    position: relative;
}

.widget-user {
    height: 200px;
    padding: 16px 9px 16px 16px !important;
}

.card-ico {
    margin-right: 55px;
}

.info-card {
    margin-top: 35px;
}

.text-card-ico {
    font-family: 'GucciSans-Bold';
    color: white;
}

.events_button {
    height: 37px;
    margin-right: 75px !important;
    width: 150px;
}

.events_button_disabled {
    height: 37px;
    margin-right: 75px !important;
    width: 150px;
    background-color: #999696bf !important;
    color: grey !important;
}

.user_dots {
    margin-top: -4px;
    width: 28px;
    height: 28px;
}

.email_input {
    font-size: 14px;
    border: 1px solid var(--color-gray);
    border-radius: 4px;
    width: 99%;
}

.event_select {
    background: #f2f2f2;
    padding: 5px;
    border-radius: 6px;
    font-family: 'GucciSans-Book';
    border: 1px solid var(--color-gray);
    text-align: -webkit-center;
}

.event_select_disabled {
    background: #949494;
    padding: 5px;
    border-radius: 6px;
    font-family: 'GucciSans-Book';
    border: 1px solid var(--color-gray);
    text-align: -webkit-center;
    color: #8e8e8e
}

.filter_by {
    padding: 10px;
    height: 37px;
    min-width: 100px;
    font-family: 'GucciSans-Bold';
    font-size: 18px;
    margin-right: 10px;
    margin-left: 2px;
    margin-top: 20px;
    scroll-snap-align: 'center'
}

.search_users {
    position: relative;
    border-radius: 4px;
    /* background-color: #f0f0f0;*/
    background-color: #e9edef;
    margin-right: 15px;
    margin-top: 15px;
    margin-left: 0;
    width: 30%;
}

.search_users:hover {
    background-color: #f5f5f5;
}

.clear_search_user:hover {
    background-color: #f5f5f5 !important;
}

.search_icon {
    padding: 0px 16px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.search_user_input {
    padding: 8px 8px 8px 0px !important;
    padding-left: calc(1em + 32px) !important;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    width: 100% !important;
    font-family: 'GucciSans-Book';
}

.search_user_root {
    color: inherit;
    width: 100%;
}

@media(min-width:960px) {
    .search_user_input {
        width: 50ch !important;
    }
}

/* manage roles */


.toggle-button-manage-roles {
    height: 21px;
    width: 22px;
    /*position: absolute;*/
    cursor: pointer;
    -webkit-appearance: none;
    /*hides the default checkbox*/
    /*position: absolute;*/
    transition: 0.10s;
    background-color: #ffffff;
    text-align: center;
    color: white;
    border-radius: 3px;
    outline: none;
    border: 1px solid black;
    padding: 0px !important;
    font-size: 1em;
    top: 3px;
}

.toggle-button-manage-roles:checked {
    background-color: #f2f2f2;
    padding: 0px !important;
}

.toggle-button-manage-roles:checked:before {
    content: '✔';
    color: #ca0000 !important;
    padding: 0px !important;
    font-size: 1em;
}


/*events dialog*/

.custom_event_button {
    text-align: -webkit-center;
    text-transform: uppercase;
    height: 32px;
    margin: 5px;
    padding: 6px;
    background-color: #e0e0e0;
    font-family: "GucciSans-Book" !important;
    cursor: pointer !important;
    border-radius: 3px;
    width: max-content;
}

.button_events_container {
    width: 550px;
}

.delete_event {
    margin-top: -3px
}

/*Map*/
.imageMap {
    width: 32% !important;
    height: fit-content;
    overflow: hidden;
    position: relative;
}

.tools {
    /* text-align: -webkit-center; */
    position: absolute;
    z-index: 5;
    /* font-size: 33px; */
    bottom: 11px;
    right: 11px;
    display: grid;
}

.map_container {
    width: 100%;
    text-align: -webkit-center;
}

.display {
    position: absolute;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 7.5px;
    color: red;
}

.text_center {
    text-align: center;
}

@keyframes goUp {
    0% {
        bottom: -450px;
    }

    100% {
        bottom: 0;
    }
}

@keyframes goDown {
    0% {
        bottom: 0;
    }

    100% {
        bottom: -450px;
    }
}

#dialog {
    width: 100%;
    height: 450px;
    background-color: white;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 6;
}

.close_modal {
    float: right;
    margin-right: 20px;
    margin-top: 20px;
}

.animated {
    animation: goUp 1s forwards;
}

.hide {
    animation: goDown 1s forwards;
}

.down_arrow {
    position: absolute;
    top: 0;
    right: 25px;
}

.display_title {
    width: 100%;
    text-align: -webkit-center;
    margin-top: 10px;
    font-size: 35px;
    font-family: 'GucciSans-Bold';
}

.middle {
    height: 81px;
    vertical-align: bottom;
}

.my-menu {
    width: 400px;
    font-size: 36px !important;
    font-family: 'GucciSans-Bold';
    background-color: white !important;
    margin: 0px 0px 0px 25px !important;

    text-align: -webkit-center !important;
}

.my-option {
    color: black;
    font-family: 'GucciSans-Book';
    display: block
}

.my-select {
    width: fit-content;
    max-width: 524px;
    overflow: hidden;
    height: 80px;
    font-size: 36px !important;
    font-family: 'GucciSans-Bold';
    background-color: white;
    margin: 25px 0px -21px 51px;
}

.hotspot_name {
    width: 90%;
    height: 70px;
    font-size: 24px !important;
    font-family: 'GucciSans-Bold';
    background-color: white;
    margin: 9px 0px -21px 60px;
}

.products_container {
    width: 96%;
    max-height: 325px;
    margin-left: 40px;
    overflow: auto;
    display: flex;
    flex-flow: wrap;
    z-index: 6;
}

.product {
    min-width: 390px;
    background-color: #f2f1f6;
    height: 170px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: -7px;
    padding: 25px;
    display: flex;
    z-index: 6
}

.product_img {
    height: 100px;
    width: 108px;
    align-self: center;
    background-color: white;
    margin-right: 20px;
    background-position: center;
}

.product_details {
    margin-top: 12px;
    font-family: 'GucciSans-Book';
    font-size: 16px;

}

.product_smc {
    font-size: 16px;
    color: black;
    color: #4096f9;
}

.product_sku {
    font-size: 16px;
    color: black;
}

.product_name {
    font-size: 16px;
    color: black;
}

.product_position {
    font-size: 16px;
    color: black;
}

#img_viewer {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: white;
    top: 0;
    text-align: -webkit-center;
    overflow: hidden;
    z-index: 6;
}

#image {
    background-size: contain;
    background-repeat: no-repeat;
    width: 30%;
    height: inherit;
    background-position: center;
    margin-top: -2%;
}

.select_hotspot {
    width: 20%;
    margin-left: 78px;
    margin-bottom: 16px;
}

#si {
    padding-top: 7px;
    margin-left: -25px;
}

.search-container {
    width: 75% !important;
    text-align: -webkit-center;
    display: flex;
    margin-top: -35px;
    margin-bottom: 10px;
}

.search_icon_svg {
    padding-top: 8px;
    margin-left: -25px;
    height: 50px;
}

.input-button {
    font-family: 'GucciSans-Book';
    width: 100%;
    border: 0;
    border-bottom: 2px solid black;
    outline: 0;
    font-size: 1.2rem;
    color: black;
    padding: 0px 0;
    background: transparent;
    height: 35px;
}